<template>
  <section class="order-recovery-form">
    <h3 v-translate>Referencia del pedido</h3>
    <form ref="contactDataForm" autocomplete="on">
      <div class="card column is-12">
        <div class="column columns">
          <div class="column is-12">
            <FieldInput
              ref="lookup_ref"
              :errors="errors.lookup_ref"
              :focus="true"
              name="lookup_ref"
              autocomplete="referencia"
              v-model="formData.lookup_ref"
              :required="true"
              :label="strings['Referencia']"
            />
          </div>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import FormMixin from "@/mixins/FormMixin"

export default {
  name: "OrderRecoveryForm",
  mixins: [FormMixin],
  props: {
    errors: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        lookup_ref: null,
      },
    }
  },
  computed: {
    strings() {
      return {
        Referencia: this.$gettext("Referencia"),
      }
    },
  },
  watch: {
    formData: {
      handler() {
        this.$emit("update:data", this.formData)
      },
      deep: true,
    },
  },
}
</script>
