import _ from "lodash"

import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class FormMixin extends Vue {
  formData: any = {}

  get cleanedData() {
    return _.omitBy(this.formData, (value) => {
      return _.isNull(value)
    })
  }

  fillForm(source: any = {}) {
    _.each(_.keys(this.formData), (key) => {
      if (_.has(source, key)) {
        this.formData[key] = source[key]
      }
    })
  }
}
