<template>
  <PageDefault>
    <template v-slot:navbar-start>
      <div class="navbar-item">
        <a class="button is-link has-text-white bg-none" @click="goWeb">
          <b-icon pack="fas" icon="arrow-left"></b-icon>
        </a>
      </div>
    </template>
    <template v-slot:navbar-brand>
      <div class="navbar-item">
        <a @click="goWeb">
          <img :src="$theme().image" :alt="$theme().name" />
        </a>
      </div>
    </template>
    <template v-slot:content>
      <div class="annotation">
        <translate>
          Por favor, introduzca la referencia de la operación indicada en el correo
          electrónico enviado por Ataquilla.com.
        </translate>
      </div>
      <OrderRecoveryForm :errors="errors" :data.sync="formData" />
      <div class="card column is-12">
        <div class="column columns">
          <div class="column is-12">
            <vue-recaptcha
              :sitekey="captcha_id"
              @verify="captcha_verified"
              @expired="captcha_expired"
            ></vue-recaptcha>
          </div>
        </div>
      </div>
      <section class="block mb-3">
        <div style="height: 15px"></div>
        <button
          @click="search_lookup_ref"
          data-cy="button-search-lookup_ref"
          class="button button-2406 fullwidth-mobile is-info"
          type="submit"
          :disabled="!searchEnabled"
        >
          <translate>Consultar</translate>
        </button>
        <div style="height: 75px"></div>
      </section>
      <section class="block mb-3">
        <table class="table result-table mb-0" v-show="resultVisible & resultOk">
          <thead>
            <tr>
              <th>
                <translate>Fecha</translate>
              </th>
              <th>
                <translate>Evento</translate>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ date }}
              </td>
              <td>
                {{ event }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <section class="download-section">
                  <div
                    class="download-button button button-2406 is-info py-3 px-4"
                    @click="throttledDownload"
                  >
                    <b-icon icon="download" class="mr-3" />
                    <translate
                      :translate-n="numTickets"
                      translate-plural="Descargar entradas"
                    >
                      Descargar entrada
                    </translate>
                  </div>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section class="result_info" v-show="resultVisible & !resultOk">
        <div class="description">
          <div translate>
            No dispones de entradas pendientes de recoger, si necesitas recuperar tus
            entradas puedes contactar con nosotros en el correo ataquilla@afundacion.org
          </div>
        </div>
      </section>
    </template>
  </PageDefault>
</template>
<script>
import _ from "lodash"
import VueRecaptcha from "vue-recaptcha"
import OrderRecoveryForm from "@/components/sections/OrderRecoveryForm"

export default {
  name: "OrderRecovery",
  components: {
    OrderRecoveryForm,
    VueRecaptcha,
  },
  data() {
    return {
      errors: {},
      formData: {},
      resultVisible: false,
      resultOk: false,
      numTickets: 0,
      date: "",
      event: "",
      orderDocumentURL: null,
      searchEnabled: false,
      captcha_id: this.$env.captcha_id,
      captcha_token: "",
      throttledDownload: _.throttle(this.downloadPDF, 2000),
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script")
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    )
    recaptchaScript.setAttribute("async", true)
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    async printOrderTickets(token) {
      return this.$api
        .printOrderTickets(this.formData.lookup_ref, token)
        .then((orderDocumentUrl) => {
          this.orderDocumentURL = orderDocumentUrl
        })
    },
    downloadPDF() {
      window.location = this.orderDocumentURL
    },
    goWeb() {
      if (this.$theme().href) {
        window.location.href = this.$theme().href
      }
    },
    captcha_verified: function (recaptchaToken) {
      if (recaptchaToken) {
        this.searchEnabled = true
        this.captcha_token = recaptchaToken
      }
    },
    captcha_expired: function () {
      this.searchEnabled = false
    },
    search_lookup_ref: function (e) {
      e.preventDefault()
      this.searchEnabled = false
      this.resultVisible = true
      this.formData["captcha_token"] = this.captcha_token
      this.$api
        .searchOrder(this.formData)
        .then((response) => {
          this.resultVisible = true
          this.resultOk = true
          this.date = response.date
          this.event = response.event
          this.numTickets = parseInt(response.numTickets)
          this.printOrderTickets(response.token)
          window.grecaptcha.reset()
        })
        .catch(() => {
          this.resultVisible = true
          this.resultOk = false
          window.grecaptcha.reset()
        })
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.annotation
  background: rgba($grey, .2)
  color: darken($grey, 20%)
  font-weight: 500
  text-align: center
  margin: 1rem auto 1.5rem auto
  @include from($tablet)
    padding: 1.5rem .75rem
  @include until($tablet)
    padding: .75rem

.page-order-recovery
  margin-top: 2rem
  @include until($widescreen)
    margin-top: 1.5rem
  @include from($widescreen)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    & > *
      width: 50%

      .contact-data-form
         order: 0
         width: 100%
.result-table
  margin-top: .5rem
  width: 100%
  tfoot
    tr:not(:first-child) td
      border-top: none
  &.desktop
    @include until($desktop)
      display: none
  .representation
    font-size: 13px
    font-weight: 100
.result-info
  text-align: center
  padding: 1rem
  font-size: .95rem
.download-section
  @include from($mobile)
    margin: 2rem 0
    display: flex
    justify-content: space-between
    align-items: flex-start
  @include until($mobile)
    margin: 1rem 0

  .download-button
    @include until($mobile)
      width: 100%
      margin: 1rem 0 2rem 0
</style>
